import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext.js';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { addMonths, format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { loadTemplate, nullDashTemplate, utcToLocalTemplate } from '../components/ColumnUtil.js';
import { ExcelButton, ExportUtil } from '../utilities/ExportUtil.js';

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const tooltipTxt = "Sensors used for people counting are not perfectly accurate.\n" +
	"Core data for this table is from Amadeus; flight times are estimates.\n" +
	"Calculations cannot account for staff entering and exiting the bridge.\n" +
	"!! These factors allow for ~90% accuracy !!";

export const FlightDetail = () => {
	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [dataLoading, setDataLoading] = useState(false);
	const [startDt, setStartDt] = useState(addMonths(new Date(), -1));
	const [endDt, setEndDt] = useState(new Date());

	const user = useContext(UserContext);

	useEffect(() => {
		fetchReportData();
	}, [])

	const fetchReportData = () => {
		setTableData([]);
		setDataLoading(true);

		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		user.apiCall('flightdetail/' + startDateStr + 'z/' + endDateStr +'z')
			.then((res) => {
                if (res.data !== null) {

              
                    setTableData(res.data);
					setFilteredTableData(res.data);
					setDataLoading(false);
				}
			});
	}

	const handleExport = (data) => {
		const headerOrder = [
			"scheduledBlockTime",
			"estimatedBlockTime",
			"actualBlockTime",
			"airline",
			"flightType",
			"partnerAirport",
			"gate",
			"flightNumber",
			"peopleCount",
			"seatCount",
			"loadFactor",
			"aircraftType"
		]
		let exportData = data.map((entry) => {
			const newSched = format(new Date(entry.scheduledBlockTime), "M/d/yyyy h:mm a");
			const newEstim = format(new Date(entry.estimatedBlockTime), "M/d/yyyy h:mm a");
			const newActu = format(new Date(entry.actualBlockTime), "M/d/yyyy h:mm a");
			return {
				...entry,
				loadFactor: `${Math.floor((entry.loadFactor ?? 0).toFixed(2))}%`,
				scheduledBlockTime: (newSched.startsWith("1/1/0001") ? "--" : newSched),
				estimatedBlockTime: (newEstim.startsWith("1/1/0001") ? "--" : newEstim),
				actualBlockTime: (newActu.startsWith("1/1/0001") ? "--" : newActu)
			}
		});

		const fileName = `FlightDetail_${format(startDt, "yyyyMMdd")}-${format(endDt, "yyyyMMdd")}`;
		ExportUtil.exportExcel(fileName, exportData, headerOrder);
	}

	return (
		<div id="FlightDetail">
			<div className="date-selectors">
				<div>
					<label htmlFor="startdate">Start Date</label>
					<Calendar id="startdate"
						value={startDt}
						onChange={(e) => setStartDt(e.value)}
					/>
				</div>
				<div>
					<label htmlFor="enddate">End Date</label>
					<Calendar id="enddate"
						value={endDt}
						onChange={(e) => setEndDt(e.value)}
					/>
				</div>
				{!dataLoading && (
					<Button label="Fetch"
						onClick={fetchReportData}
					/>
				)}
				{dataLoading && (
					<ProgressSpinner />
				)}
				<Tooltip target=".tooltip-target" />
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="left"
					data-pr-at="left-240 center"
					data-pr-my="left-200 center"
				/>
			</div>
			<DataTable value={tableData}
				size="small"
				filterDisplay="row"
				onValueChange={(flt) => setFilteredTableData(flt)}
				sortField="scheduledBlockTime"
				sortOrder="0"
				sortMode="multiple"
				footer={<ExcelButton handleExport={() => handleExport(filteredTableData)} />}
				virtualScrollerOptions={{ itemSize: 35 }}
				scrollHeight="80vh"
				scrollable
			>
				<Column field="scheduledBlockTime"
					header="Scheduled Time"
					body={(d) => utcToLocalTemplate(d, "scheduledBlockTime")}
					align="center"
					style={{minWidth: "155px"}}
					sortable
				/>
				<Column field="estimatedBlockTime"
					header="Estimated Time"
					body={(d) => utcToLocalTemplate(d, "estimatedBlockTime")}
					align="center"
					style={{minWidth: "155px"}}
					sortable
				/>
				<Column field="actualBlockTime"
					header="Actual Time"
					body={(d) => utcToLocalTemplate(d, "actualBlockTime")}
					align="center"
					style={{minWidth: "155px"}}
					sortable
				/>
				<Column field="airline"
					header="Airline"
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="flightType"
					header="Arr | Dep"
					style={{maxWidth: "140px"}}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="partnerAirport"
					header="Partner Airport"
					style={{maxWidth: "150px"}}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="gate"
					header="Gate"
					style={{maxWidth: "100px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="flightNumber"
					header="Flight #"
					style={{maxWidth: "120px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="peopleCount"
					header="PAX (Est.)"
					style={{maxWidth: "130px"}}
					sortable
				/>
				<Column field="seatCount"
					header="Seat Count"
					body={(d) => nullDashTemplate(d, "seatCount")}
					style={{maxWidth: "140px"}}
					sortable
				/>
                <Column field="loadFactor"
					header="Load Factor"
                    body={(d) => loadTemplate(d, "loadFactor")}
					style={{width: "130px"}}
					sortable
				/>
				<Column field="aircraftType"
					header="Aircraft"
					style={{maxWidth: "120px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
			</DataTable>
		</div>
	);
}
