import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Route, Routes } from 'react-router-dom';
import { UserContext } from './UserContext';

import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import { Dashboard } from './pages/Dashboard';
import { GateSummary } from './pages/GateSummary';
import { FlightCount } from './pages/FlightCount';
import { FlightDetail } from './pages/FlightDetail';
import { GateCount } from './pages/GateCount';
import { FlightRoute } from './pages/FlightRoute';
import { InvalidFlights } from './pages/InvalidFlights';
import { AdvancedCharts } from './pages/AdvancedCharts';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {
	const [menuActive, setMenuActive] = useState(false);
	const [menuMode, setMenuMode] = useState('static');
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
	const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
	const [configActive, setConfigActive] = useState(false);

	const user = useContext(UserContext);

	const groupAccess = process.env.REACT_APP_GROUP_ACCESS?.split(",");
	const hasAccess = user?.userInfo?.groups?.some(group => groupAccess?.includes(group));

	let menuClick = false;
	let configClick = false;

	const menu = [
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-o',
			items: [
				{ label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
				{ label: 'Flight Detail', icon: 'pi pi-fw pi-wrench', to: '/flightdetail' },
				{ label: 'Flight Count', icon: 'pi pi-fw pi-megaphone', to: '/flightcount' },
				{ label: 'Gate PAX', icon: 'pi pi-fw pi-truck', to: '/gatecount' },
				{ label: 'Route PAX', icon: 'pi pi-fw pi-calculator', to: '/flightroute' },
				{ label: 'Month Summary', icon: 'pi pi-fw pi-chart-pie', to: '/gatesummary' },
				{ label: 'Invalid Flights', icon: 'pi pi-fw pi-flag', to: '/invalidflights' },
				{ label: 'Advanced Charts', icon: 'pi pi-fw pi-chart-pie', to: '/advancedcharts' }
			]
		}
	];

	const routers = [
		{ path: "/", element: <Dashboard /> },
		{ path: "/home", element: <Dashboard /> },
		{ path: "/gatesummary", element: <GateSummary /> },
		{ path: "/flightcount", element: <FlightCount /> },
		{ path: "/gatecount", element: <GateCount /> },
		{ path: "/flightroute", element: <FlightRoute /> },
		{ path: "/flightdetail", element: <FlightDetail /> },
		{ path: "/invalidflights", element: <InvalidFlights /> },
		{ path: "/advancedcharts", element: <AdvancedCharts /> }
	];

	const onDocumentClick = () => {
		if (!menuClick) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false);
			}

			hideOverlayMenu();
		}

		if (configActive && !configClick) {
			setConfigActive(false);
		}

		menuClick = false;
		configClick = false;
	};

	const onMenuClick = () => {
		menuClick = true;
	};

	const onMenuitemClick = (event) => {
		if (!event.item.items) {
			hideOverlayMenu();
		}

		if (!event.item.items && (isSlim() || isHorizontal())) {
			setMenuActive(false);
		}
	};

	const onRootMenuitemClick = () => {
		setMenuActive(prevMenuActive => !prevMenuActive);
	};

	const onMenuButtonClick = (event) => {
		menuClick = true;

		if (menuMode === 'overlay') {
			setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
		}
		else {
			if (isDesktop())
				setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
			else
				setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
		}

		event.preventDefault();
	};

	const onTopbarMenuButtonClick = (event) => {
		hideOverlayMenu();
		event.preventDefault();
	};

	const hideOverlayMenu = () => {
		setOverlayMenuActive(false);
		setStaticMenuMobileActive(false);
	};

	const isDesktop = () => {
		return window.innerWidth > 1024;
	};

	const isHorizontal = () => {
		return menuMode === 'horizontal';
	};

	const isSlim = () => {
		return menuMode === 'slim';
	};

	const isMenuVisible = () => {
		if (isDesktop()) {
			if (menuMode === 'static')
				return !staticMenuDesktopInactive;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
		else {
			if (staticMenuMobileActive)
				return true;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
	};

	let containerClassName = classNames('layout-wrapper', {
		'menu-layout-static': menuMode !== 'overlay',
		'menu-layout-overlay': menuMode === 'overlay',
		'layout-menu-overlay-active': overlayMenuActive,
		'menu-layout-slim': menuMode === 'slim',
		'menu-layout-horizontal': menuMode === 'horizontal',
		'layout-menu-static-inactive': staticMenuDesktopInactive,
		'layout-menu-static-active': staticMenuMobileActive,
		'p-input-filled': false,
		'p-ripple-disabled': true,
	});
	let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': false, 'layout-menu-container-inactive': !isMenuVisible() });

	return (
		<div className={containerClassName} onClick={onDocumentClick}>
			<div className="layout-wrapper-content">
				<AppTopBar activeUser={user}
					onMenuButtonClick={onMenuButtonClick}
					onTopbarMenuButtonClick={onTopbarMenuButtonClick}
				/>
				{hasAccess ? (
					<>
						<div className={menuClassName} onClick={onMenuClick}>
							<div className="menu-scroll-content">
								<AppMenu model={menu}
									menuMode={menuMode}
									active={menuActive}
									onMenuitemClick={onMenuitemClick}
									onRootMenuitemClick={onRootMenuitemClick}
								/>
							</div>
						</div>
						<div className="layout-main">
							<Routes>
								{routers.map((router, index) => (
									<Route key={`router${index}`} path={router.path} element={router.element} />
								))}
							</Routes>
						</div>
						< AppFooter />
					</>) :
					(

						< div className="layout-main" style={{ textAlign: "center", padding: "20px", marginRight: "200px" }}>
							<h1 style={{ fontSize: "20px", fontWeight: "bold", color: "#333", marginTop: "100px" }}>
								Access Denied
							</h1>
							<p style={{ fontSize: "20px", color: "#555" }}>
								Please request access to the Data & Analytics team to proceed.
							</p>
						</div>
					)
				}
			</div>
		</div>
	);
}

export default App;
