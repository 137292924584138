import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { format } from "date-fns";

/*
    chartData: { [{ }, ...], [{ }, ...] }
    dataField: "field",
	totals?: { }
    title
*/
export const SummaryChart = (props) => {
	const [assembledData, setAssembledData] = useState({})

	useEffect(() => {
		const newData = {
			labels: props.chartData?.departures?.map((val) =>
				format(new Date(val.month.split("T")[0] + " 11:00:00Z"), "MM-yy")
			),
			datasets: [
				{
					label: "Departures",
					data: props.chartData?.departures?.map((val) => val[props.dataField]),
					borderColor: "#11E"
				}, {
					label: "Arrivals",
					data: props.chartData?.arrivals?.map((val) => val[props.dataField]),
					borderColor: "#E11"
				}
			]
		};
		setAssembledData(newData);
	}, [props.chartData])

	return (
		<div className="summary-chart">
			<div className="counts" style={props.style}>
				<div className="total">
					<p>{props.title}</p>
					{props.totals !== null && props.totals !== undefined && (
						<>{props.totals.total.toLocaleString()}</>
					)}
					{props.totals === null || props.totals === undefined && (
						<>
							{(props.chartData?.arrivals?.reduce((cumul, cur) => cumul + cur[props.dataField], 0) +
							props.chartData?.departures?.reduce((cumul, cur) => cumul + cur[props.dataField], 0)
							).toLocaleString()}
						</>
					)}
				</div>
				<div className="depart">
					{props.totals !== null && props.totals !== undefined && (
						<>{props.totals.departure.toLocaleString()}</>
					)}
					{props.totals === null || props.totals === undefined && (
						<>{props.chartData?.departures?.reduce(
							(cumul, cur) => cumul + cur[props.dataField], 0
						).toLocaleString()}
						</>
					)}
					<p>Departures</p>
				</div>
				<div className="arrive">
					{props.totals !== null && props.totals !== undefined && (
						<>{props.totals.arrival.toLocaleString()}</>
					)}
					{props.totals === null || props.totals === undefined && (
						<>{props.chartData?.arrivals?.reduce(
							(cumul, cur) => cumul + cur[props.dataField], 0
						).toLocaleString()}
						</>
					)}
					<p>Arrivals</p>
				</div>
			</div>
			<Chart type="line" data={assembledData} />
		</div>
	);
}