import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import landing from '../img/landing.jpg';
import 'primeflex/primeflex.css';

const tooltipTxt = "Just like this!";

export const Dashboard = () => {
	return (
		<div id="Dashboard">
			<h1>Welcome to GateMate!</h1>
			<img src={landing} alt="Landing Page" />
			<p>
				Each page will have a{" "}
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="top"
				/>
				{" "}icon. Hovering the mouse over this icon will show a brief explanation
				{" "}of the data displayed on the page.
			</p>
			<Tooltip target=".tooltip-target" />
		</div>
	);
}
