import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext.js';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { format, addMonths } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { ExcelButton, ExportUtil } from '../utilities/ExportUtil.js';

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const tooltipTxt = "Sensors used for people counting are not perfectly accurate.\n" +
	"Calculations cannot account for staff entering and exiting the bridge.\n" +
	"!! These factors allow for ~90% accuracy !!";

export const GateCount = () => {
	const [tableData, setTableData] = useState({});
	const [startDt, setStartDt] = useState(addMonths(new Date(), -1));
	const [endDt, setEndDt] = useState(new Date());

	const user = useContext(UserContext);

	useEffect(() => {
		fetchReportData();
	}, [])

	const fetchReportData = () => {
		setTableData({});

		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		user.apiCall('GateCount/' + startDateStr + 'z/' + endDateStr + 'z')
			.then((res) => {
				if (res.data != null) {
					setTableData(res.data)
				}
			});
	}

	const handleExport = (data) => {
		let exportData = [];
		let fields = Object.keys(data);
		let dataWithTotals = {};
		let finalLength = 0;

		fields.forEach((val) => {
			let concourse = [...data[val]];
			concourse.sort((e1, e2) => e1.gate.localeCompare(e2.gate));
			const totalPax = concourse.reduce((prev, curr) => prev += curr.pax, 0);
			concourse.push({
				gate: "Total",
				pax: totalPax
			});

			dataWithTotals[val] = concourse;
			if(concourse.length > finalLength) {
				finalLength = concourse.length;
			}
		})

		for(let x = 0; x < finalLength; x++) {
			let entry = {};
			fields.forEach((val) => {
				if(dataWithTotals[val].length > x) {
					entry[`${val}_gate`] = dataWithTotals[val][x].gate;
					entry[`${val}_pax`] = dataWithTotals[val][x].pax.toLocaleString();
				}
			});
			exportData.push(entry);
		}

		const fileName = `GatePAX_${format(startDt, "yyyyMMdd")}-${format(endDt, "yyyyMMdd")}`;
		ExportUtil.exportExcel(fileName, exportData);
	}

	const paxBodyTemplate = (number) => (
		number.pax.toLocaleString()
	)

	const paxFooterTemplate = (data) => {
		const total = (data ?? []).reduce((total, cur) => total += cur.pax, 0);
		return total.toLocaleString();
	}

	return (
		<div id="GatePax">
			<div className="date-selectors">
				<div>
					<label htmlFor="startdate">Start Date</label>
					<Calendar id="startdate"
						value={startDt}
						onChange={(e) => setStartDt(e.value)}
					/>
				</div>
				<div>
					<label htmlFor="enddate">End Date</label>
					<Calendar id="enddate"
						value={endDt}
						onChange={(e) => setEndDt(e.value)}
					/>
				</div>
				<Button label="Fetch"
					onClick={fetchReportData}
				/>
				<Tooltip target=".tooltip-target" />
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="left"
					data-pr-at="left-240 center"
					data-pr-my="left-200 center"
				/>
			</div>
			<div className="table-section">
				<h1>Concourse A</h1>
				<DataTable value={tableData.terminalA}
					responsiveLayout="scroll"
					size="small"
					emptyMessage="Loading..."
					sortField="gate"
					sortOrder="1"
				>
					<Column field="gate"
						header="Gate"
						footer="Total"
					/>
					<Column field="pax"
						header="Pax"
						body={paxBodyTemplate}
						footer={(d) => paxFooterTemplate(d.props?.value ?? [])}
					/>
				</DataTable>
			</div>
			<div className="table-section">
				<h1>Concourse B</h1>
				<DataTable value={tableData.terminalB}
					responsiveLayout="scroll"
					size="small"
					emptyMessage="Loading..."
					sortField="gate"
					sortOrder="1"
				>
					<Column field="gate"
						header="Gate"
						footer="Total"
					/>
					<Column field="pax"
						header="Pax"
						body={paxBodyTemplate}
						footer={(d) => paxFooterTemplate(d.props?.value ?? [])}
					/>
				</DataTable>
			</div>
			<div className="table-section">
				<h1>Concourse C</h1>
				<DataTable value={tableData.terminalC}
					responsiveLayout="scroll"
					size="small"
					emptyMessage="Loading..."
					sortField="gate"
					sortOrder="1"
				>
					<Column field="gate"
						header="Gate"
						footer="Total"
					/>
					<Column field="pax"
						header="Pax"
						body={paxBodyTemplate}
						footer={(d) => paxFooterTemplate(d.props?.value ?? [])}
					/>
				</DataTable>
			</div>
			<div className="table-section">
				<h1>Concourse D</h1>
				<DataTable value={tableData.terminalD}
					responsiveLayout="scroll"
					size="small"
					emptyMessage="Loading..."
					sortField="gate"
					sortOrder="1"
				>
					<Column field="gate"
						header="Gate"
						footer="Total"
					/>
					<Column field="pax"
						header="Pax"
						body={paxBodyTemplate}
						footer={(d) => paxFooterTemplate(d.props?.value ?? [])}
					/>
				</DataTable>
			</div>
			<div className="export-footer">
				<ExcelButton handleExport={() => handleExport(tableData)} />
			</div>
		</div>
	);
}
