import React, { useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../UserContext.js';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { addMonths, format } from 'date-fns';
import { FilterMatchMode } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { booleanTemplate, nullDashTemplate, utcToLocalTemplate } from '../components/ColumnUtil.js';

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const baseFilters = {
	"scheduledBlockUtc": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"airline": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"flightNumber": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
	"flightType": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"partnerAirport": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"gate": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"noGate": { value: null, matchMode: FilterMatchMode.EQUALS },
	"noPax": { value: null, matchMode: FilterMatchMode.EQUALS },
	"noAircraft": { value: null, matchMode: FilterMatchMode.EQUALS },
	"noSeats": { value: null, matchMode: FilterMatchMode.EQUALS },
	"hasDeviceIssue": { value: null, matchMode: FilterMatchMode.EQUALS },
	"totalErrors": { value: null, matchMode: FilterMatchMode.EQUALS }
}
const baseSort = [
	{ field: "airline", order: 1 },
	{ field: "flightNumber", order: 1 },
	{ field: "scheduledBlockUtc", order: -1 }
]
const tooltipTxt = "When flights are missing fields, it is not possible to get PAX and/or Load Factor.\n" +
    "The O symbols show which error(s) a flight has that prevents these calculations.\n" +
    "Expanding an entry will show more details about the flight.\n\n" +
	"The table can be sorted by multiple columns.\n" +
	"Hold the 'ctrl' key and click a header to add a column to the sort.";

export const InvalidFlights = () => {
	const [dataLoading, setDataLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [startDt, setStartDt] = useState(addMonths(new Date(), -1));
	const [endDt, setEndDt] = useState(new Date());
	const [expandedRows, setExpandedRows] = useState(null);

	const user = useContext(UserContext);

	useEffect(() => {
		fetchReportData();
	}, [])

	const fetchReportData = () => {
		setTableData([]);
		setDataLoading(true);

		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		user.apiCall('invalidflights/' + startDateStr + 'z/' + endDateStr +'z')
			.then((res) => {
				if (res.data !== null) {
					setTableData(res.data);
					setDataLoading(false);
				}
			})
			.catch(() => {
				user.showErrorToast("Failed to load data");
				setDataLoading(false);
			});
	}

	const expandedTemplate = (rowData) => (
		<div className="outer-expand">
			<div>
				<div><span>Scheduled:</span>{utcToLocalTemplate(rowData, "scheduledBlockUtc")}</div>
				<div><span>Estimated:</span>{utcToLocalTemplate(rowData, "estimatedBlockUtc")}</div>
				<div><span>Actual:</span>{utcToLocalTemplate(rowData, "actualBlockUtc")}</div>
			</div>
			<div>
				<div><span>Airline:</span>{rowData.airline}</div>
				<div><span>Flight #:</span>{rowData.flightNumber}</div>
				<div><span>Type:</span>{rowData.flightType}</div>
				<div><span>Airport:</span>{rowData.partnerAirport}</div>
			</div>
			<div>
				<div><span>Gate:</span>{rowData.gate !== "" ? rowData.gate : "--"}</div>
				<div><span>PAX Count:</span>{rowData.paxCount}</div>
				<div><span>Aircraft:</span>{rowData.aircraftType}</div>
				<div><span>Seat Count:</span>{nullDashTemplate(rowData, "seatCount")}</div>
			</div>
		</div>
	)

	const booleanFilterTemplate = (opts) => (
		<Dropdown value={opts.value}
			options={[{label: "True", value: 1}, {label: "False", value: 0}]}
			onChange={(e) => opts.filterApplyCallback(e.value)}
			placeholder="Any"
			className="p-column-filter"
			showClear
		/>
	)

	return (
		<div id="InvalidFlights">
			<div className="date-selectors">
				<div>
					<label htmlFor="startdate">Start Date</label>
					<Calendar id="startdate"
						value={startDt}
						onChange={(e) => setStartDt(e.value)}
					/>
				</div>
				<div>
					<label htmlFor="enddate">End Date</label>
					<Calendar id="enddate"
						value={endDt}
						onChange={(e) => setEndDt(e.value)}
					/>
				</div>
				{!dataLoading && (
					<Button label="Fetch"
						onClick={fetchReportData}
					/>
				)}
				{dataLoading && (
					<ProgressSpinner />
				)}
				<Tooltip target=".tooltip-target" />
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="left"
					data-pr-at="left-306 center"
					data-pr-my="left-200 center"
				/>
			</div>
			<DataTable value={tableData}
				size="small"
				multiSortMeta={baseSort}
				sortMode="multiple"
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={expandedTemplate}
				expandedRows={expandedRows}
				filterDisplay="row"
				filters={baseFilters}
				virtualScrollerOptions={{ itemSize: 52 }}
				scrollHeight="80vh"
				scrollable
				removableSort
			>
				<Column expander />
				<Column field="scheduledBlockUtc"
					header="Scheduled Time"
					body={(d) => utcToLocalTemplate(d, "scheduledBlockUtc")}
					style={{maxWidth: "140px"}}
					sortable
				/>
				<Column field="airline"
					header="Airline"
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="flightNumber"
					header="Flight #"
					style={{maxWidth: "110px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="flightType"
					header="Arr | Dep"
					style={{maxWidth: "120px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="partnerAirport"
					header="Partner Airport"
					style={{maxWidth: "120px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="gate"
					header="Gate"
					body={(d) => d.gate !== "" ? d.gate : "--"}
					style={{maxWidth: "110px"}}
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="noGate"
					header="No Gate"
					body={(d) => booleanTemplate(d, "noGate")}
					align="center"
					alignHeader="left"
					filterElement={booleanFilterTemplate}
					showFilterMenu={false}
					showClearButton={false}
					filter
					sortable
				/>
				<Column field="noPax"
					header="No PAX"
					body={(d) => booleanTemplate(d, "noPax")}
					align="center"
					alignHeader="left"
					filterElement={booleanFilterTemplate}
					showFilterMenu={false}
					showClearButton={false}
					filter
					sortable
				/>
				<Column field="noAircraft"
					header="No Aircraft"
					body={(d) => booleanTemplate(d, "noAircraft")}
					align="center"
					alignHeader="left"
					filterElement={booleanFilterTemplate}
					showFilterMenu={false}
					showClearButton={false}
					filter
					sortable
				/>
				<Column field="noSeats"
					header="No Seats"
					body={(d) => booleanTemplate(d, "noSeats")}
					align="center"
					alignHeader="left"
					filterElement={booleanFilterTemplate}
					showFilterMenu={false}
					showClearButton={false}
					filter
					sortable
				/>
				<Column field="hasDeviceIssue"
					header="Sensor Issue"
					body={(d) => booleanTemplate(d, "hasDeviceIssue")}
					align="center"
					alignHeader="left"
					filterElement={booleanFilterTemplate}
					showFilterMenu={false}
					showClearButton={false}
					filter
					sortable
				/>
				<Column field="totalErrors"
					header="Errors"
					align="center"
					alignHeader="left"
					className="big"
					style={{maxWidth: "100px"}}
					showFilterMenu={false}
					filter
					sortable
				/>
			</DataTable>
		</div>
	);
}
