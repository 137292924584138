import React, { useContext, useEffect, useRef, useState } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UserContext } from '../UserContext.js';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { addMonths, format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const tooltipTxt = "Select a time period, airline, and destination to view load factor.\n" +
	"Clicking the 'Day of Week' button will slice the data by day in the selected time period.\n" +
	"Clicking the 'Hour of Day' button will slice the data by hour in the selected time period.\n\n" +
	"Addition airlines and destinations can be added to the currently-selected datasets.\n" +
	"Select another airline and destination pair and click the same button for the current time slice.\n" +
	"Click the 'Clear' button to reset and select a different time period and/or time slice.";
const baseChartOpts = {
	maintainAspectRatio: false,
	aspectRatio: 0.8,
	scales: {
		x: {
			ticks: {
				color: "#000",
				font: {
					weight: 500
				}
			},
			grid: {
				display: false,
				drawBorder: false
			},
			title: {
				display: true,
				text: "Day of Week"
			}
		},
		y: {
			max: 100,
			beginAtZero: true,
			ticks: {
				color: "#000"
			},
			grid: {
				color: "#CCC",
				drawBorder: false
			},
			title: {
				display: true,
				text: "Load Factor"
			}
		}
	}
};
const barColors = [
	"0, 0, 180",
	"245, 130, 48",
	"68, 200, 75",
	"145, 30, 180",
	"255, 255, 25",
	"230, 25, 75",
	"128, 128, 128",
	"240, 50, 230",
	"170, 110, 40",
	"0, 128, 128"
];
// https://sashamaps.net/docs/resources/20-colors/

export const AdvancedCharts = () => {
	const [baseData, setBaseData] = useState([]);
	const [chartData, setChartData] = useState({});
	const [chartOpts, setChartOpts] = useState(baseChartOpts);
	const [chartPlugins, setChartPlugins] = useState([]);
	const [airlineOpts, setAirlineOpts] = useState([]);
	const [destinationOpts, setDestinationOpts] = useState([]);
	const [startDt, setStartDt] = useState(addMonths(new Date(), -1));
	const [endDt, setEndDt] = useState(new Date());
	const [airline, setAirline] = useState("");
	const [destination, setDestination] = useState("");
	const [chartType, setChartType] = useState("");

	const user = useContext(UserContext);
	const airlineDebounce = useRef(null);
	const startArr = useRef();
	const endArr = useRef();

	useEffect(() => {
		user.apiCall("barchart/dropdowns/airline")
			.then((res) => {
				setAirlineOpts(res.data);
			});

		const topLabels = {
			id: "topLabels",
			afterDataestsDraw(chart, args, pluginOptions) {
				const { ctx, scales: { x, y } } = chart;

				ctx.font = "bold 24px sans-serif";
				ctx.fillStyle = "#333";
				ctx.textAlign = "center";

				chart.data.datasets.forEach((set, setIdx) => {
					set.data.forEach((val, idx) => {
						ctx.fillText(val, x.getPixelForValue(idx), /*chart.getDatasetMeta(1).data[idx].y - */100);
					});
				});
			}
		};

		setChartPlugins([ChartDataLabels, topLabels]);
	},[])

	useEffect(() => {
		if(airlineDebounce.current !== null) {
			clearTimeout(airlineDebounce.current);
		}

		if(airline === "") {
			return;
		}

		airlineDebounce.current = setTimeout(() => {
			const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
			const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
			const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
			const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

			user.apiCall(`barchart/dropdowns/destination/${airline.label}/${startDateStr}/${endDateStr}`)
				.then((res) => {
					setDestinationOpts(res.data);
				})
				.catch(ex => console.log(ex));
		}, 800);
	},[airline, startDt, endDt])

	/* granularity = 'day', 'hour' */
	const fetchReportData = (granularity) => {
		if(!airline.label || !destination.label) {
			user.showErrorToast("Not all fields have been selected.");
			return;
		}
		setDestination("");

		let shouldBreak = false;
		baseData.forEach((val) => {
			if(val.params === `${airline.label}${destination.label}`) {
				shouldBreak = true;
			}
		})
		if(shouldBreak) {
			user.showInfoToast("The requested [Airline - Destination] pair has already been selected.");
			return;
		}

		setChartType(granularity);
		setChartOpts((prev) => {
			prev.scales.x.title.text = (granularity === "day" ? "Day of Week" : "Hour of Day");
			return prev;
		})

		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		const query = `timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
		const dtParts = `${startDateStr}z/${endDateStr}z`;
		user.apiCall(`barchart${granularity}/${dtParts}/${airline.label}/${destination.label}?${query}`)
			.then((res) => {
				if (res.data !== null) {
					setBaseData((prevData) => {
						let newData = [{
							params: `${airline.label}${destination.label}`,
							content: res.data
						}];

						if(prevData.length > 0 && prevData[0].content.length === newData[0].content.length) {
							newData = [...prevData, ...newData];
						}

						setChartData((prev) => {
							const parsedData = parseNewDataset(res.data, prevData, prev);
							if(parsedData.labels.length === 0) {
								user.showInfoToast("Blank data set found for requested [Airline - Destination] pair");
							}
							return parsedData;
						});

						return newData;
					});
				}
			});
	}

	const parseNewDataset = (incomingData, prevData, prev) => {
		/* Trim x-axis zero-count days and hours */
		let startAt = -1;
		let endAt = -1;
		incomingData.forEach((val, idx) => {
			if(val.seatCalc !== 0 && Math.floor((val.paxCalc / val.seatCalc) * 100) > 0) {
				if(startAt === -1) {
					startAt = idx;
				}
				endAt = idx + 1;
			}
		});

		if(endArr.current === null || endArr.current === undefined) {
			endArr.current = endAt;
		}
		if(startArr.current === null || startArr.current === undefined) {
			startArr.current = startAt;
		}

		let reprocess = false;
		if(endAt !== -1 && endAt > endArr.current) {
			reprocess = true;
			endArr.current = endAt;
		}
		if(startAt !== -1 && (startArr.current === -1 || startAt < startArr.current)) {
			reprocess = true;
			startArr.current = startAt;
		}

		const rgb = barColors[prevData.length % barColors.length];
		let trimmedData = [];
		for(let x = startArr.current; x < endArr.current; x++) {
			if(incomingData[x].seatCalc === 0) {
				trimmedData.push(0);
			}
			else {
				const load = Math.floor((incomingData[x].paxCalc / incomingData[x].seatCalc) * 100);
				trimmedData.push(load);
			}
		}
		const newDataset = {
			label: `${airline.label} - [${destination.label}]`,
			data: trimmedData,
			backgroundColor: [`rgba(${rgb}, 0.4)`],
			borderColor: [`rgba(${rgb}, 1)`],
			borderWidth: 1
		};

		if(reprocess) {
			prev.datasets.forEach((set, setIdx) => {
				let newTrim = [];
				for(let x = startArr.current; x < endArr.current; x++) {
					if(prevData[setIdx].content[x].seatCalc === 0) {
						newTrim.push(0);
					}
					else {
						const elemPax = prevData[setIdx].content[x].paxCalc;
						const elemSeat = prevData[setIdx].content[x].seatCalc;
						const load = Math.floor((elemPax / elemSeat) * 100);
						newTrim.push(load);
					}
				}
				set.data = newTrim;
			});
		}

		let labels = [];
		for(let x = startArr.current; x < endArr.current; x++) {
			labels.push(incomingData[x].label);
		}
		return {
			labels: labels,
			datasets: [...prev.datasets, newDataset]
		};
	}

	const clearData = () => {
		setBaseData([]);
		setChartData({});
		setChartType("");
		startArr.current = null;
		endArr.current = null;
	}

	return (
		<div id="AdvancedCharts">
			<div className="data-selectors">
				<Button label="Clear"
					icon="pi pi-eraser"
					onClick={clearData}
				/>
				<div className="fields">
					<div>
						<label htmlFor="startdate">Start Date</label>
						<Calendar id="startdate"
							value={startDt}
							onChange={(e) => setStartDt(e.value)}
							disabled={(chartData.labels ?? []).length !== 0}
						/>
					</div>
					<div>
						<label htmlFor="enddate">End Date</label>
						<Calendar id="enddate"
							value={endDt}
							onChange={(e) => setEndDt(e.value)}
							disabled={(chartData.labels ?? []).length !== 0}
						/>
					</div>
					<div>
						<span className="p-float-label">
							<Dropdown id="airline"
								value={airline}
								onChange={(e) => setAirline(e.value)}
								options={airlineOpts}
								filter
							/>
							<label htmlFor="airline">Airline</label>
						</span>
					</div>
					<div>
						<span className="p-float-label">
							<Dropdown id="destination"
								value={destination}
								onChange={(e) => setDestination(e.value)}
								options={destinationOpts}
								filterMatchMode="startsWith"
								filter
							/>
							<label htmlFor="destination">Destination Code</label>
						</span>
					</div>
				</div>
				<div className="fetch-btns">
					<p>Fetch by:</p>
					<Button label="Day of Week"
						onClick={() => fetchReportData("day")}
						disabled={chartType === "hour"}
					/>
					<Button label="Hour of Day"
						onClick={() => fetchReportData("hour")}
						disabled={chartType === "day"}
					/>
				</div>
				<Tooltip target=".tooltip-target" />
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="left"
					data-pr-at="left-306 center"
					data-pr-my="left-200 center"
				/>
			</div>
			<div className="chart">
				{(chartData.labels ?? []).length !== 0 && (
					<h2>Departure Load Factor by {chartData.labels.length === 7 ? "Day" : "Hour"}</h2>
				)}
				<Chart type="bar"
					data={chartData}
					options={chartOpts}
					plugins={chartPlugins}
				/>
			</div>
		</div>
	)
}