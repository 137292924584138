import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext.js';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { addMonths } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { SummaryChart } from '../components/SummaryChart.js';

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const tooltipTxt = "Sensors used for people counting are not perfectly accurate.\n" +
	"Calculations cannot account for staff entering and exiting the bridge.\n" +
	"!! These factors allow for ~90% accuracy !!";

export const GateSummary = () => {
	const [tableData, setTableData] = useState({});
	const [startDt, setStartDt] = useState(addMonths(new Date(), -2));
	const [endDt, setEndDt] = useState(new Date());

	const user = useContext(UserContext);

	useEffect(() => {
		fetchReportData();
	}, [])

	const fetchReportData = () => {
		setTableData({});

		const startDateStr = formatInTimeZone(new Date(startDt), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endDt), "Etc/UTC", dateFmtStr);

		user.apiCall('monthsummary/' + startDateStr + 'z/' + endDateStr + 'z')
			.then((res) => {
				if (res.data != null) {
					let truncTotals = {};
					Object.keys(res.data.totals).forEach((fld) =>
						truncTotals[fld] = res.data.totals[fld].toFixed(2) * 100
					);
					res.data.totals = truncTotals;
					setTableData(res.data);
				}
			});
	}

	return (
		<div>
			<div className="date-selectors">
				<div>
					<label htmlFor="startdate">Start Date</label>
					<Calendar id="startdate"
						value={startDt}
						onChange={(e) => setStartDt(e.value)}
						view="month"
						dateFormat="mm/y"
					/>
				</div>
				<div>
					<label htmlFor="enddate">End Date</label>
					<Calendar id="enddate"
						value={endDt}
						onChange={(e) => setEndDt(e.value)}
						view="month"
						dateFormat="mm/y"
					/>
				</div>
				<Button label="Fetch"
					onClick={fetchReportData}
				/>
				<Tooltip target=".tooltip-target" />
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="left"
					data-pr-at="left-240 center"
					data-pr-my="left-200 center"
				/>
			</div>
			<SummaryChart title="Passengers"
				chartData={tableData}
				dataField="paxTotal"
				style={{backgroundColor: "#ECFFFF"}}
			/>
			<SummaryChart title="Flights"
				chartData={tableData}
				dataField="flightCount"
				style={{backgroundColor: "#DBF7EB"}}
			/>
			<SummaryChart title="Load Factor"
				chartData={tableData}
				dataField="loadFactor"
				totals={tableData.totals}
				style={{backgroundColor: "#E5FFDA"}}
			/>
		</div>
	);
}