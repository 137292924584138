import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import App from './App.js';
import { useMsal } from "@azure/msal-react";
import { UserContext, UserContextProvider } from "./UserContext.js";

const AppWrapper = () => {
	const { instance, accounts } = useMsal();
	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	useEffect(() => {
		const handleAuth = async () => {
			try {
				// Wait for the redirect promise to resolve
				//this needs to happen, because the accounts are null until this is done
				//so it goes into an endless redirect loop
				await instance.handleRedirectPromise();

				// If no accounts are found, initiate login redirect
				if (accounts.length === 0) {
					await instance.loginRedirect({
						scopes: ['user.read'],
					});
				}
			} catch (error) {
				console.error('Authentication error:', error);
			}
		};

		handleAuth();
	}, [accounts, instance]);

	return (
		<Routes>
			<Route path="/error" component={Error} />
			<Route path="*" element={<UserContextProvider><App /></UserContextProvider>} />
		</Routes>
	);
}

export default AppWrapper;
