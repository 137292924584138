import React from "react";
import * as xlsx from "xlsx";
import * as FileSaver from 'file-saver';
import format from "date-fns-tz/format";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

export class ExportUtil {
	static exportExcel(tableName, tableData, headerArr, widthArr) {
		let sheetParams = {};
		if(headerArr !== null && headerArr !== undefined) {
			sheetParams.header = headerArr;
		}
		const worksheet = xlsx.utils.json_to_sheet(tableData, sheetParams);
		if(widthArr !== null && widthArr !== undefined) {
			worksheet["!cols"] = widthArr.map((w) => {
				return {"wpx": w}
			})
		}
		const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data = new Blob([excelBuffer], {
			type: EXCEL_TYPE
		});

		const fileName = `${tableName}_Export_` + format(new Date(), "yyyy-MM-dd") + EXCEL_EXTENSION;
		FileSaver.saveAs(data, fileName);
	}
}

/*
	handleExport
*/
export const ExcelButton = (props) => (
	<>
		<Tooltip target=".excel-button" position="top">
			Export to XLSX
		</Tooltip>
		<Button type="button"
			icon="pi pi-file-excel"
			onClick={props.handleExport}
			className="p-button-success mr-2 excel-button"
			style={{ margin: 2 }}
		/>
	</>
)